import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../../../layouts/"
import SEO from "../../../components/seo/"
import Crumbs from "../../../components/breadcrumb/"
import BackNav from "../../../components/back-nav/"

import Split from "../../../components/split/"
import FilterBlock from "../../../components/filter-block/"
import LinkBlock from "../../../components/link-block/"

import { psColour, wlColour } from "../../../data/data.colours"

const CdevPage = ({data, pageContext, location}) => {
	
	const image = useStaticQuery(graphql`
		query {
			rectoryBg: file(relativePath: { eq: "backgrounds/sheep-01.jpg" }) {
				childImageSharp {
					fluid(maxWidth: 2000) {
						...GatsbyImageSharpFluid_withWebp
					}
				}
			}
			aston01: file(relativePath: { eq: "properties/aston41/aston41-01.jpg" }) {
				childImageSharp {
					fluid(maxWidth: 2000) {
						...GatsbyImageSharpFluid_withWebp
					}
				}
			}
			churchFarm01: file(relativePath: { eq: "properties/church-farm/church-farm-01.jpg" }) {
				childImageSharp {
					fluid(maxWidth: 2000) {
						...GatsbyImageSharpFluid_withWebp
					}
				}
			}
		}
	`)
	
	const [filter, setFilter] = useState(`All`);
	
	const filterFunction = (ref) => {
		filter === ref ? setFilter(`All`) : setFilter(ref)
	}
	
	const filterItems = [`All`, `Commercial`, `Residential`]

	return (
		<>
			<SEO title="Current Developments"
				path={ location.pathname }/>
			<Layout>
				<Crumbs
					pageContext={pageContext}
					location={location}/>	
				<BackNav
					hex={wlColour.hex}
					link={`/w-lamb`}/>		
				<Split>
					<FilterBlock 
						filterState={ filter }
						filterItems={ filterItems }
						filterFunction={ filterFunction }
						title={`Current developments`}
						titleTag={ `h1` }
						text={`
							<p>From purchasing land for the construction of offices, warehouses and mixed-use schemes, to funding and selling the finished project we use the skill set of our small, yet highly-experienced team, to maximise returns. Our diligent collective understands how to put together a viable investment, what matters, and more importantly, what will add value to the project.</p>
							<p>With demand for new housing at an all-time high, we have worked from inception to completion on many high-quality builds and conversions. We are ever-conscious of the growing importance to produce sustainable properties in-keeping with their local environment.</p>
						`}
						bgColour={ wlColour.slug }/>
					<Split vertical={true}>
						{ (filter === `All` || filter === `Commercial`) &&
							<LinkBlock
								hex={wlColour.hex}
								link={`/w-lamb/current-developments/aston-41`}
								text={`Aston 41 (Phase 3), Aston Clinton`}
								background={ data.aston01.childImageSharp.fluid } />
						}
						{ (filter === `All` || filter === `Commercial` || filter === `Residential`) &&
							<LinkBlock
								hex={wlColour.hex}
								link={`/w-lamb/current-developments/church-farm`}
								text={`Church Farm, Aldbury`}
								background={ data.churchFarm01.childImageSharp.fluid } />
						}
						{ (filter === `All` || filter === `Residential`) &&
							<LinkBlock
								hex={wlColour.hex}
								link={`/w-lamb/current-developments/rectory-farm`}
								text={`Rectory Farm, Puttenham`}
								subtext={`Coming soon`}
								background={ data.rectoryBg.childImageSharp.fluid }
								position={`60% 90%`} />
						}
					</Split>
				</Split>
			</Layout>
		</>
	)
}

export default CdevPage
